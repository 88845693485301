import React from 'react'
import { graphql } from 'gatsby'
import { Services } from '@templates'
import {
  prismicImageQuoteFields,
  prismicPartnerPageFields,
  servicesDoubleParagraph,
  servicesCta,
  servicesPartnerBlock,
  prismicMailingListFields,
} from '@graphql/fragments'

const ServicesPage = ({ data }) => <Services data={data} />
ServicesPage.fragments = [
  prismicImageQuoteFields,
  prismicPartnerPageFields,
  servicesDoubleParagraph,
  servicesCta,
  servicesPartnerBlock,
]

export default ServicesPage

export const query = graphql`
  query PrismicServices {
    prismic {
      allServices_pages {
        edges {
          node {
            seodescription
            seotitle
            intro_heading
            intro_text
            services {
              service {
                ... on PRISMIC_Service {
                  name
                  heading
                  intro
                  summary
                  _meta {
                    uid
                  }
                }
              }
            }
            body {
              ...prismicImageQuoteFields
              ...servicesDoubleParagraph
              ...servicesCta
              ...servicesPartnerBlock
            }
            mailinglist {
              ... on PRISMIC_Mailing_list {
                ...prismicMailingListFields
              }
            }
          }
        }
      }
    }
  }
`

ServicesPage.query = query
